<template>
  <main class="about page" :style="{'background-image': 'url(' + details.image_about +')'}">
    <section class="mainContent">
      <sectionHeader/>
      <div class="row">
        <div class="stats">
          <div id="batiment" class="stat-item">
            <div class="item-jauge"><div class="filled"></div></div>
            <label><b><span>31</span>/50 métiers</b> dans le domaine du <b>bâtiment</b></label>
          </div>

          <div id="agriculture" class="stat-item">
            <div class="item-jauge"><div class="filled"></div></div>
            <label><b>0/20 métiers</b> dans le domaine de <b>l’agriculture et nature</b></label>
          </div>

          <div id="mobilite" class="stat-item">
            <div class="item-jauge"><div class="filled"></div></div>
            <label><b>0/10 métiers</b> dans le domaine de la <b>mobilité</b></label>
          </div>

          <div id="generaux" class="stat-item">
            <div class="item-jauge"><div class="filled"></div></div>
            <label><b><span>14</span>/30 Métiers</b> dans les <b>domaines transversaux - domaines généraux</b></label>
          </div>

          <div id="divers" class="stat-item">
            <div class="item-jauge"><div class="filled"></div></div>
            <label><b>0/20 Métiers</b> dans les domaines <b>divers</b></label>
          </div>

          <div id="emergeants" class="stat-item">
            <div class="item-jauge"><div class="filled"></div></div>
            <label><b>0/20 métiers</b> dans les domaines <b>émergeants></b></label>
          </div>

        </div>

        <div class="content">
          <h1>Précisions</h1>

            <h3>Ce quiz vous présente <b>45 professions</b>, sous l'angle de la <b>transition énergétique et écologique</b>, afin de découvrir celles qui pourraient vous correspondre ou vous intéresser.</h3>
<p>Il ne s'agit pas d'un test d’orientation scolaire ou professionnelle, mais d'un outil ludique destiné à éveiller votre curiosité. <b>Pour en savoir plus et définir le choix de votre futur métier, n'hésitez pas à consulter <a href="https://www.orientation.ch/" target="_blank">orientation.ch</a> et à prendre contact avec une <a href="https://www.citedesmetiers.ch/thematiques/formations-et-metiers/entretien-sans-rendez-vous-avec-nos-conseiller-ere-s/" target="_blank">conseillère ou un conseiller en orientation de l'OFPC</a></b>.</p>
<p>Sous sa forme actuelle, le quiz porte principalement sur les métiers du bâtiment et de la construction. Il va s'ouvrir progressivement à d'autres domaines (mobilité, nature et environnement, etc.) pour vous faire découvrir à terme quelque 150 métiers.</p>
<router-link to="/questions" class="btn">Poursuivre vers le quiz</router-link>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import quizLogo from '@/components/quizLogo.vue'
import sectionHeader from '@/components/sectionHeader.vue'

export default {
  name: 'About',
  data () {
    return {
    }
  },
  components: {
    sectionHeader
  },
  computed: {
    details () {
      return this.$store.state.details
    }
  }
}
</script>

<style lang="scss" scoped>
  $jauge_width-large: 35vw;
  $jauge_width-md: 50%;
  $jauge_width-sm: 100%;

  .about {
    background: no-repeat center center;
    background-size: cover;

    .mainContent {
      background-color: rgba(00,00,00, 0.3 );

      .section-header {
        .quiz_title {
          color: #fff;
          border-color: #fff;
          text-align: right;
        }

        #headericon {
          fill:#fff;
        }

        @media all and (max-width: 1024px ) {
          margin-bottom: 5em;
        }
      }

      .btn {
        margin-top: 100px;
        border-color: #fff;
        color: #fff;

        @media all and (max-width: 540px) {
          margin-top: 30px;
          margin-bottom: auto;
        }

        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
      .row {
        width: 100%;
        display: flex;

        @media all and (max-width: 1024px ) {
          flex-direction: column;
        }

        .stats {
          flex: 1;

          @media all and (max-width: 1024px ) {
            width: 100%;
            margin-bottom: 3em;
            overflow: hidden;
          }
        }
        .content {
          color: #fff;
          max-width: 500px;

          @media all and (max-width: 1024px ) {
            max-width: 100%;
            text-align: left;
          }

          h1 {
            text-transform: uppercase;
            margin-bottom: 1rem;
          }

          p {
              a {
                color: #fff;
                text-decoration: underline;
              }
            @media all and (max-width: 1024px ) {

            }
          }

          h3 {
            font-weight: 300;
            font-family: 'Titillium Web', sans-serif;

            b {
              text-transform: uppercase;
            }
          }

          .btn {
            display: inline-block;
            margin-top: 1em;
            width: auto;
          }
        }
      }

      .stat-item {
        width: 100%;
        display: flex;
        margin-top: 20px;
        margin-left: -100px;
        align-items: center;

        @media all and (max-width: 1280px ) {
          display: block;
        }

        @media all and (max-width: 1024px ) {
          display: block;
          margin-left: -15px;
        }

        b {
          text-transform: uppercase;
        }

        .item-jauge {
          width: $jauge_width-large;
          height: 30px;
          display: block;
          border:solid 3px #fff;
          border-radius: 18px;
          background-color: rgba(00,00,00, 0.4);

          @media all and (max-width: 1280px ) {
            width: $jauge_width-sm;
          }

          @media all and (max-width: 1024px ) {
            width: $jauge_width-sm;
          }

          .filled {
            width: 50%;
            height: 100%;
            display: block;
            border:solid 3px #fff;
            border-radius: 18px;
            background-color: #53F56C;
            margin-top: -3px;
            margin-bottom: -3px;
          }
        }

        label {
          margin-left: 15px;
          color: #fff;
          display: block;
          line-height: 1.2;
          text-align: left;

          @media all and (max-width: 1280px ) {
            margin-top: 5px;
          }

          span {
            color: #53F56C;
          }
        }
      }
      #batiment .item-jauge {
        .filled{
          width: 62%;
        }
      }
      #agriculture .item-jauge {
        width: calc((#{$jauge_width-large}*43.8) / 100);
        @media all and (max-width: 1280px ) {
          width: calc((#{$jauge_width-sm}*43.8) / 100);
        }
        .filled{
          width: 0;
        }
      }
      #mobilite .item-jauge {
        width: calc((#{$jauge_width-large}*21.7) / 100);
        @media all and (max-width: 1280px ) {
          width: calc((#{$jauge_width-sm}*21.7) / 100);
        }
        .filled{
          width: 0;
        }
      }
      #generaux .item-jauge {
        width: calc((#{$jauge_width-large}*67.4) / 100);
        @media all and (max-width: 1280px ) {
          width: calc((#{$jauge_width-sm}*67.4) / 100);
        }
        .filled{
          width:46.6%;
        }
      }
      #divers .item-jauge {
        width: calc((#{$jauge_width-large}*43.8) / 100);
        @media all and (max-width: 1280px ) {
          width: calc((#{$jauge_width-sm}*43.8) / 100);
        }
        .filled{
          width: 0;
        }
      }
      #emergeants .item-jauge {
        width: calc((#{$jauge_width-large}*43.8) / 100);
        @media all and (max-width: 1280px ) {
          width: calc((#{$jauge_width-sm}*43.8) / 100);
        }
        .filled{
          width: 0;
        }
      }
    }
  }

</style>
